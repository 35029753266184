


const interfaces =
{
  //客户工单
  costomerOrderAdd: '/v1/work_order/network_access/apply/add',  //新装工单
  costomerOrderEdit: '/v1/work_order/network_access/apply/edit',
  costomerRepairAdd: '/v1/work_order/fault_repair/apply/add',
  costomerRepairEdit: '/v1/work_order/fault_repair/apply/edit',
  constmerOrderPage: '/v1/tenant/work_order/page',
  //注销
  logout: '/v1/secure/logout',
  //主机
  hostAdd: '/v1/ops/zabbix_host/add',
  hostEdit: '/v1/ops/zabbix_host/edit',
  hostDelete: '/v1/ops/zabbix_host/delete',
  hostPage: '/v1/ops/store_and_gateway/page',
  hostRefresh: '/v1/ops/zabbix_host/refresh',
  storeBindHost: '/v1/ops/zabbix_host/page',
  // storeAndGatewayPage:'/v1/ops/store_and_gateway/page',



  //监控
  ops_zabbix_trigger_problems: '/v1/ops/zabbix_trigger/problems',
  zabbix_host_page: '/v1/ops/zabbix_host/page',
  store_and_zabbix_relate: '/v1/ops/store_and_zabbix_host/relate',
  store_and_zabbix_unrelate: '/v1/ops/store_and_zabbix_host/un_relate',
  get_gplot: '/v1/ops/topological_diagrams/get_by_store_id',
  save_gplot: '/v1/ops/topological_diagram/save',
  get_home_data: "/v1/ops/zabbix/get_home_data",
  get_statistical_data: "/v1/ops/zabbix/get_statistical_data",
  get_host_data: "/v1/ops/zabbix/get_host_data",
  gloptDeviceType: '/v1/ops/network_equipment_type/selector',
  getHostId: '/v1/ops/zabbix_host/get_by_store_id',
  lanStatus: "/v1/ops/store/lan_status/get",
  alertHistories: '/v1/ops/zabbix_trigger/problems',
  onlineStatus: '/v1/ops/zabbix/online_status',
  nodePortStatus: '/v1/ops/topological_diagram/node/status/all',
  ethFlowHistory: '/v1/ops/eth_flow_histories/get',
  storeGetGatewayId: '/v1/ops/store_and_gateway/get_by_id',
  coordinateAll: '/v1/ops/store/coordinate/all',
  gatewayTimeAndMemory: '/v1/ops/zabbix/time_and_memory',
  assetsStatictics: '/v1/ops/statistics/store/lan/assets',

  zabbixAlert: '/v1/ops/statistics/store/zabbix/alert',
  alertGroupByRegion: "/v1/ops/count/store/zabbix/alert/group_by_region",
  offlineAlertGroupByTime: "/v1/ops/sequence/zabbix/offline_alert/group_by_time",
  //触发器
  interfaceTriggerSet: '/v1/ops/physical_interface/trigger/set',
  interfaceTriggerSelector: '/v1/ops/zabbix_trigger/selector',
  interfaceTriggerGetGateway: '/v1/ops/physical_interface/trigger/get_by_gateway_id',

  //统计数据
  contracts_summary: '/v1/contract/contracts_summary',
  workOrderStatictics: "/v1/work_order/count/by_status",
  workOrderGroupByTime: '/v1/work_order/sequence/count/group_by_time',

  //商圈
  business_cluster_add: '/v1/prospective_customer/business_cluster/add',
  business_cluster_edit: '/v1/prospective_customer/business_cluster/edit',
  business_cluster_delete: '/v1/prospective_customer/business_cluster/delete',
  business_cluster_page: '/v1/prospective_customer/business_cluster/page',
  business_cluster_get_by_id: '/v1/prospective_customer/business_cluster/get_by_id',




  //财务
  //发票类型列表
  finance_account_add: '/v1/finance/finance_account/add',
  finance_account_edit: '/v1/finance/finance_account/edit',
  finance_account_delete: '/v1/finance/finance_account/delete',
  finance_account_page: '/v1/finance/finance_account/page',
  finance_account_get_by_id: "/v1/finance/finance_account/get_by_id",
  finance_account_type_selector: "/v1/finance/finance_account_type/selector",


  //财务
  //发票类型列表
  invoice_type_add: '/v1/finance/invoice_type/add',
  invoice_type_edit: '/v1/finance/invoice_type/edit',
  invoice_type_delete: '/v1/finance/invoice_type/delete',
  invoice_type_page: '/v1/finance/invoice_type/page',
  invoice_type_get_by_id: "/v1/finance/invoice_type/get_by_id",


  //财务
  //收款记录
  revenue_record_add: '/v1/finance/revenue_record/add',
  revenue_record_edit: '/v1/finance/revenue_record/edit',
  revenue_record_delete: '/v1/finance/revenue_record/delete',
  revenue_record_page: '/v1/finance/revenue_record/page',
  revenue_record_get_by_id: "/v1/finance/revenue_record/get_by_id",

  //财务
  //合同收款记录
  contract_revenue_record_add: '/v1/finance/contract_revenue_record/add',
  contract_revenue_record_edit: '/v1/finance/contract_revenue_record/edit',
  contract_revenue_record_delete: '/v1/finance/contract_revenue_record/delete',
  contract_revenue_record_page: '/v1/finance/contract_revenue_record/page',
  contract_revenue_record_get_by_id: "/v1/finance/contract_revenue_record/get_by_id",


  //财务
  //网络接入成本
  network_access_cost_add: '/v1/work_order/network_access_work_cost/add',
  network_access_cost_edit: '/v1/work_order/network_access_work_cost/edit',
  network_access_cost_delete: '/v1/work_order/network_access_work_cost/delete',
  network_access_cost_page: '/v1/work_order/network_access_work_cost/page',



  //商机 产品
  pc_current_product_add: '/v1/prospective_customer/pc_current_product/add',
  pc_current_product_edit: '/v1/prospective_customer/pc_current_product/edit',
  pc_current_product_delete: '/v1/prospective_customer/pc_current_product/delete',
  pc_current_product_page: '/v1/prospective_customer/pc_current_product/page',



  //商机 选择器
  brand_rating: '/v1/prospective_customer/brand_rating/selector',
  survey_priority: '/v1/prospective_customer/Survey_priority/selector',
  operate_model: '/v1/prospective_customer/operate_model/selector',
  pc_source: '/v1/prospective_customer/pc_source/selector',
  pc_key_person_role: '/v1/prospective_customer/pc_key_person_role/selector',
  pc_follow_stage: '/v1/prospective_customer/pc_follow_stage/selector',
  pc_status: '/v1/prospective_customer/pc_status/selector',

  //商机 准客户
  prospective_customer_add: '/v1/prospective_customer/prospective_customer/add',
  prospective_customer_edit: '/v1/prospective_customer/prospective_customer/edit',
  prospective_customer_delete: '/v1/prospective_customer/prospective_customer/delete',
  prospective_customer_page: '/v1/prospective_customer/prospective_customer/page',


  //商机 报告
  survey_report_add: '/v1/prospective_customer/survey_report/add',
  survey_report_edit: '/v1/prospective_customer/survey_report/edit',
  survey_report_delete: '/v1/prospective_customer/survey_report/delete',
  survey_report_page: '/v1/prospective_customer/survey_report/page',
  survey_report_get_by_id: '/v1/prospective_customer/survey_report/get_by_id',

  //商机 需求项
  pc_requirement_item_add: '/v1/prospective_customer/pc_requirement_item/add',
  pc_requirement_item_edit: '/v1/prospective_customer/pc_requirement_item/edit',
  pc_requirement_item_delete: '/v1/prospective_customer/pc_requirement_item/delete',
  pc_requirement_item_page: '/v1/prospective_customer/pc_requirement_item/page',

  //商机 跟踪日志
  trace_log_delete: '/v1/prospective_customer/pc_trace_log/delete',
  //行业
  tradeAdd: "/v1/customer/industry/add",
  tradeEdit: "/v1/customer/industry/edit",
  tradeDelete: "/v1/customer/industry/delete",
  tradePage: "/v1/customer/industry/page",
  tradeAll: "/v1/customer/industry/all",

  //子行业
  subTradeAdd: "/v1/customer/sub_industry/add",
  subTradeEdit: "/v1/customer/sub_industry/edit",
  subTradeDelete: "/v1/customer/sub_industry/delete",
  subTradePage: "/v1/customer/sub_industry/page",
  subTradeSelector: "/v1/customer/sub_industry/selector",
  //标签
  tagSelector: "/v1/extend_info/talent_tag_category/selector",
  tagAdd: "/v1/extend_info/talent_tag/add",
  tagEdit: "/v1/extend_info/talent_tag/edit",
  tagDelete: "/v1/extend_info/talent_tag/delete",
  tagPage: "/v1/extend_info/talent_tag/page",

  //通用文件上传接口
  fileSelector: "/v1/file/get_file_type_selector",
  fileGet: "/v1/file/get?file_id=",


  //添加合约图片上传接口
  upload: "/v1/contract/attachment/upload",
  commonUpload: "/v1/file/upload",

  //外勤人员
  outWorkerPage: '/v1/outworker/page',
  myOrder: "/v1/outworker/work_order/my",
  outWorkAdd: "/v1/outworker/add",
  outWorkEdit: "/v1/outworker/edit",
  outWorkDelete: "/v1/outworker/delete",

  outWorkInternet: "/v1/outworker/my_network_access_work/set",
  outWorkSource: "/v1/outworker/my_resource_exploration_work/set",
  outWorkInstall: "/v1/outworker/my_gateway_install_work/set",
  outWorkRepair: "/v1/outworker/my_fault_repair_work/set",
  outWorkTestReport: "/v1/outworker/my_test_report/set",


  workContentSubmit: '/v1/outworker/work_content/submit',
  workContentSubmitReview: '/v1/work_order/outworker/work_content/submit/review',
  acceptWorkOrder: "/v1/outworker/accept_order",
  rejectWorkOrder: "/v1/outworker/drop_order",
  startWorkOrder: '/v1/outworker/work_order/start',


  workContentGetById: '/v1/work_order/work_content/get_by_id',
  workGetById: '/v1/outworker/work_order/get_by_id',
  //工单
  ticketsAdd: "/v1/work_order/add",
  ticketEdit: "/v1/work_order/edit",
  ticketPage: "/v1/work_order/page",
  ticketDelete: "/v1/work_order/delete",
  ticketsSelector: "/v1/work_order/work_order_type/selector",
  workSelector: "/v1/work_order/work_content_category/selector",
  ticketsMy: '/v1/work_order/my',

  orderStatusSelector: '/v1/work_order/work_content_status/selector',
  statusSelector: '/v1/work_order/work_order_status/selector',
  assignMaintenance: '/v1/work_order/fault_repair/onset_engineer/dispatch',


  checkOrder: "/v1/work_order/tenant/apply/review",  //申请工单的审核
  workOrderPretreatment: '/v1/work_order/fault_repair/prev_process/complete', //预处理
  assignNetworkEngineer: '/v1/work_order/fault_repair/network_engineer/dispatch', //分配网工
  handleWorkOrder: '/v1/work_order/fault_repair/remote_process/complete', //网工处理工单
  equipmentPreparation: '/v1/work_order/fault_repair/ready_devices/complete',
  appointmentTime: '/v1/work_order/fault_repair/onsite_engineer/schedule',//预约上门时间
  arriveTime: '/v1/work_order/fault_repair/onsite_engineer/arrived',//到达时间
  faultOrderSubmit: '/v1/work_order/fault_repair/onsite_engineer/submit_result',//提交维修工单
  resultAudit: '/v1/work_order/work_content/result/audit',//工作内容审核

  //设备
  deviceAdd: "/v1/warehouse/device/add",
  deviceEdit: "/v1/warehouse/device/edit",
  deviceDelete: "/v1/warehouse/device/delete",
  devicePage: "/v1/warehouse/device/page",
  deviceGetById: "/v1/warehouse/device/get_by_id",
  deviceSelector: '/v1/warehouse/device/selector',

  //设备品牌
  deviceBrandAdd: "/v1/warehouse/device_brand/add",
  deviceBrandEdit: "/v1/warehouse/device_brand/edit",
  deviceBrandDelete: "/v1/warehouse/device_brand/delete",
  deviceBrandPage: "/v1/warehouse/device_brand/page",
  deviceBrandGetById: "/v1/warehouse/device_brand/get_by_id",

  // 合约管理接口
  contractAdd: "/v1/contract/add",
  contractEdit: "/v1/contract/edit",
  contractDelete: "/v1/contract/delete",
  contractPage: "/v1/contract/page",
  contractGet: "/v1/contract/get",


  contractAttachmentType: "/v1/contract/attachment_type/selector",
  contractFileExcel: "/v1/contract/file/excel",
  contractGetID: "/v1/contract/get",

  //品牌列表
  customerAdd: "/v1/customer/add",
  customerEdit: "/v1/customer/edit",
  customerDelete: "/v1/customer/delete",
  customerGet: "/v1/customer/get",
  customerPage: "/v1/customer/page",

  //合约用户
  customerUserAdd: "/v1/customer/contract_user_info/add",
  customerUserEdit: "/v1/customer/contract_user_info/edit",
  customerUserDelete: "/v1/customer/contract_user_info/delete",
  customerUserPage: "/v1/customer/contract_user_info/page",
  customerUserGet: "/v1/customer/contract_user_info/get",


  custmerExport: "/v1/contract/export",


  getIndustryAll: '/v1/customer/industry/all',



  //导航栏管理接口
  navigatorAdd: "/v1/permission/navigation/add",
  navigatorEdit: "/v1/permission/navigation/edit",
  navigatorDelete: "/v1/permission/navigation/delete",
  navigatorAll: "/v1/permission/navigation/all",

  navigatorSet: "/v1/permission/navigation_access/set",


  // 角色管理
  roleAdd: "/v1/permission/role/add",
  roleEdit: "/v1/permission/role/edit",
  roleDelete: "/v1/permission/role/delete",
  rolePage: "/v1/permission/role/page",
  roleSet: "/v1/permission/role_access/set",
  roleId: "/v1/permission/role_access/by_role_id",
  navigationAndRoleSet: "/v1/permission/navigation_and_rule/set",

  //系统权限规则
  systermPage: "/v1/permission/system_rule/page",
  systermSet: "/v1/permission/system_rule/set",

  //系统用户
  systemUserAdd: "/v1/permission/system_user/add",
  systemUserEdit: "/v1/permission/system_user/edit",
  systemUserDelete: "/v1/permission/system_user/delete",
  systemUserGet: "/v1/permission/system_user/get",
  systemUserPage: "/v1/permission/system_user/page",


  systemMe: "/v1/secure/users/me/",




  //区域管理
  regionAdd: "/v1/region/add",
  regianlEdit: "/v1/region/edit",
  regianlDelete: "/v1/region/delete",
  regianlGet: "/v1/region/get",
  reginalPage: "/v1/region/page",
  reginalTree: "/v1/region/tree",

  //产品管理
  productAdd: "/v1/product/add",
  productEdit: "/v1/product/edit",
  productDelete: "/v1/product/delete",
  productPage: "/v1/product/page",


  product_type_selector: "/v1/product/product_type/selector",
  product_building_product_sub_type_selector: "/v1/product/building_product_sub_type/selector",
  product_industry_product_sub_type: "/v1/product/industry_product_sub_type/selector",
  product_building_product_sub_type: "/v1/product/building_product_sub_type/selector",
  product_billing_mode: "/v1/product/billing_mode/selector",


  //入户方式
  accessModePage: "/v1/product/network_access_mode/page",
  accessModeGet: "/v1/product/network_access_mode/get",
  accessModeAdd: "/v1/product/network_access_mode/add",
  accessModeDelete: "/v1/product/network_access_mode/delete",
  accessModeEdit: "/v1/product/network_access_mode/edit",


  //租户
  tenantAdd: "/v1/organization/tenant/add",
  tenantEdit: "/v1/organization/tenant/edit",
  tenantDelete: "/v1/organization/tenant/delete",
  tenantGetById: "/v1/organization/tenant/get_by_id",
  tenantPage: "/v1/organization/tenant/page",
  tenantGetWorkOrderPage: '/v1/tenant/work_order/page',

  //部门
  departmentAdd: "/v1/organization/department/add",
  departmentEdit: "/v1/organization/department/edit",
  departmentDelete: "/v1/organization/department/delete",
  departmentGetById: "/v1/organization/tendepartmentant/get_by_id",
  departmentPage: "/v1/organization/department/page",
  departmentTree: "/v1/organization/department/tree",


  //职位
  jobTitleAdd: "/v1/organization/job_title/add",
  jobTitleEdit: "/v1/organization/job_title/edit",
  jobTitleDelete: "/v1/organization/job_title/delete",
  jobTitleGetById: "/v1/organization/tenjob_titleant/get_by_id",
  jobTitlePage: "/v1/organization/job_title/page",
  jobTitlePageSetAccounts: "/v1/organization/job_title/set_accounts",
  jobTitleGetById: '/v1/organization/job_title/get_by_id',



  //选择器 
  networkProviderSelector: '/v1/product/network_provider/selector',


  //oss
  ossUpload: '/v1/oss/upload',
  ossGet: '/v1/oss/auth/get',
  ossGetFileInfo: '/v1/oss/get_file_info',


  //地图
  aliMap: '/v1/ali_map',



  //租户添加账户
  addSubAccount: '/v1/tenant/sub_account/add',
  updateSubAccount: '/v1/tenant/sub_account/edit',
  deleteSubAccount: '/v1/tenant/sub_account/delete',
  subAccountPage: '/v1/tenant/sub_account/page',

  //查询网工
  queryNetworkEngineer: '/v1/work_order/network_engineer/page',

  //品牌下的门店
  storesUnderTheBrand: '/v1/customer/store/brand_tree',

  //文档标签
  docTagAdd: '/v1/help/help/doc/tag/add',
  docTagEdit: "/v1/help/help/doc/tag/edit",
  docTagDelete: "/v1/help/help/doc/tag/delete",
  docTagPage: "/v1/help/help/doc/tag/page",

  //文档
  docAdd: '/v1/help/help/doc/add',
  docEdit: '/v1/help/help/doc/edit',
  docDelete: '/v1/help/help/doc/delete',
  docPage: '/v1/help/help/doc/page'
}




export default interfaces;
