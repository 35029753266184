<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <a-modal :maskClosable="false"
           v-model:open="showModal.order"
           title="工单"
           width="550px"
           @ok="submitOrder"
           cancelText='取消'
           okText='提交'>
    <table class='orderTable'>
      <template v-for='item in orderFields'
                :key='item.key'>
        <tr v-if='item.title=="工单类型"'>
          <td class='column'>{{item.title}}</td>
          <td>
            <a-select :bordered='false'
                      :showArrow='false'
                      :placeholder="`请选择${item.title}`"
                      :options='options.type'
                      style='width:344px'
                      v-model:value="baseData.type"
                      @change='typeChange'></a-select>
          </td>
        </tr>
        <tr v-if='item.title=="选择门店" && baseData.type=="报修"'>
          <td class='column'><span style="color: red;margin-left: 0px;">*</span>{{item.title}}</td>
          <td>
            <a-select :bordered='false'
                      :showArrow='false'
                      :placeholder="`请选择${item.title}`"
                      show-search
                      :filter-option="filterOption"
                      :options='options.store'
                      style='width:344px'
                      v-model:value="baseData.storeId"
                      @change='storeChange'></a-select>
          </td>
        </tr>
        <tr v-else-if='item.title=="区域"'>
          <td class='column'><span style="color: red;margin-left: 0px;">*</span>{{item.title}}</td>
          <td>
            <a-cascader :showArrow="false"
                        :bordered="false"
                        style='width:344px'
                        v-model:value="baseData.regionIds"
                        :show-search="{ filter }"
                        :options="options.region"
                        :placeholder="`请选择区域`" />
          </td>
        </tr>
        <tr v-else-if='["描述"].includes(item.title)'>
          <td class='column'><span style="color: red;margin-left: 0px;">*</span>{{item.title}}</td>
          <td>
            <a-input :bordered='false'
                     v-model:value="baseData[item.key]"
                     :placeholder="`请输入${item.title}`"></a-input>
          </td>
        </tr>
        <tr v-else-if='["附件"].includes(item.title)'>
          <td class='column'>{{item.title}}</td>
          <td>
            <!-- <a-upload :headers="headers"
                      v-model:file-list="fileList"
                      :data="fileType"
                      :action="uploadUrl"
                      list-type="picture"
                      class="upload-list-inline"
                      @change='uploadChange'>
              <a-button>
                <upload-outlined></upload-outlined>
                上传
              </a-button>
            </a-upload> -->
            <uploadComponent :freshFlag='uploadFresh'
                             :fileIds='fileList'
                             @update:fileIds='(event)=>fileChange(event)'>

            </uploadComponent>

          </td>
        </tr>
        <tr v-else-if='!["区域","工单类型","选择门店","描述","附件"].includes(item.title)'>
          <td class='column'> <span v-if='baseData.type=="开通"'
                  style="color: red;margin-left: 0px;">*</span>{{item.title}}</td>
          <td>
            <a-input :bordered='false'
                     v-model:value="baseData[item.key]"
                     :placeholder="`请输入${item.title}`"></a-input>

          </td>

        </tr>
      </template>
    </table>
  </a-modal>

  <a-modal :maskClosable="false"
           v-model:open="showModal.orderDetail"
           title="工单详情"
           :ok-button-props="{ style: { display: 'none' } }"
           :cancel-button-props="{ style: { display: 'none' } }"
           width="550px">
    <table class='orderTable'>
      <template v-for='item in orderDetailFields'
                :key='item.key'>
        <tr>
          <td class='column'>{{item.title}}</td>
          <td>{{baseData[item.key]}}</td>
        </tr>
      </template>
      <tr v-if='baseData.status =="已拒绝"'>
        <td class='column'>拒绝原因</td>
        <td>{{baseData.rejectReason}}</td>
      </tr>
      <template v-for='work in baseData.workContent'
                :key='work.id'>
        <template v-for='item in workType[work.type]'
                  :key='item.key'>
          <tr v-if='item.title=="附件"'>
            <td class="column">{{item.title}}</td>
            <td>
              <uploadComponent :freshFlag='uploadFresh'
                               :fileIds='fileList'
                               :isButtonShow='false'
                               @update:fileIds='(event)=>fileChange(event)'>

              </uploadComponent>
            </td>
          </tr>
          <tr v-else-if='item.title!="附件" && baseData.status !="已拒绝"'>
            <td class="column">{{item.title}}</td>
            <td>{{workRef[work.type][item.key]}}</td>
          </tr>
        </template>
      </template>
    </table>
  </a-modal>
  <TableComponent :columns="columns"
                  :data-source="dataSource.data"
                  :row-selection="rowSelection"
                  bordered>
    <template #bodyCell="{ column,record }">
      <template v-if="column.dataIndex === 'operation'">
        <div>
          <a v-if='["已申请","已拒绝"].includes(record.status)'
             style="margin:2px;color: #1884ea;"
             @click="editOrder(record)">编辑</a>
          <a v-if='!["已申请"].includes(record.status)'
             style="margin:2px;color: #1884ea;"
             @click="viewOrder(record)">查看工单</a>
        </div>
      </template>
    </template>
    <template #title>
      <div class='searchDivContainer'>
        <div class='title-box-left'>
          <a-input-group compact
                         v-for='item in searchFields'
                         :key='item.key'>
            <div class='searchLabel'>
              <label>{{item.title}}</label>
            </div>
            <a-input v-if='item.title=="关键字"'
                     :placeholder="`请输入${item.title}`"
                     v-model:value="searchData[item.key]"
                     style="width: 70%" />
            <a-select v-else-if='item.title=="类型"'
                      :placeholder="`请选择${item.title}`"
                      style="width: 150px"
                      v-model:value="searchData[item.key]"
                      :options='options.workType'
                      @change='siftTypeChange'>
            </a-select>

            <a-select v-else-if='item.title=="状态"'
                      :placeholder="`请选择${item.title}`"
                      style="width: 150px"
                      v-model:value="searchData[item.key]"
                      :options='options.orderStatus'>
            </a-select>
          </a-input-group>
          <a-button style="margin:1px"
                    @click='sift'
                    type='primary'>搜索</a-button>
          <a-button style="margin:1px"
                    @click='resetSift'
                    type='primary'>重置</a-button>
        </div>
        <div class='title-box-right'>
          <!-- <a-button style="margin:2px"
                    @click='addOrder'
                    type='primary'>添加工单</a-button> -->
        </div>
      </div>

    </template>
  </TableComponent>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import uploadComponent from '@/components/uploadComponent.vue'

import serviceAxios from "@/utils/serviceAxios";
import removeNullValues from "@/utils/removeNullValues";
import { message } from "ant-design-vue";
import { reactive, ref, onMounted, onUpdated, watch, computed } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router';
import { checkTree } from "@/utils/regionTree";
import { selector } from '@/utils/selector'
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";


import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;
dayjs.locale("zh-cn");
const uploadFresh = ref(true)

let route = useRoute()
let router = useRouter()

// table列名数据
const columns = ref([

  {
    title: "工单号",
    dataIndex: "workSn",
    key: "workSn",
    width: '180px',
  },
  {
    title: "工单状态",
    width: '100px',
    dataIndex: "status",
    key: "status"
  },
  {
    title: "工单类型",
    width: '100px',
    dataIndex: "type",
    key: "type"
  },

  {
    title: "门店名称",
    dataIndex: "storeName",
    key: "storeName",
    width: '150px',
    ellipsis: true
  },

  {
    title: "门店地址",
    dataIndex: "address",
    key: "address",
    // width: '200px',
    ellipsis: true
  },
  {
    title: "联系人",
    dataIndex: "contactName",
    key: "contactName",
    width: '100px',
  },
  {
    title: "联系电话",
    dataIndex: "contactPhone",
    key: "contactPhone",
    width: '120px',
  },
  {
    title: "描述",
    dataIndex: "desc",
    key: "desc",
    width: 'true',
    ellipsis: true
  },
  // {
  //   title: "拒绝原因",
  //   dataIndex: "rejectReason",
  //   key: "rejectReason",
  //   width: '100px',
  //   ellipsis: true
  // },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
    width: '160px',
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    width: '150px',
  }
]);

//table数据源
const dataSource = reactive(
  {
    data: [],
  }
)

//选择框数据源
const options = reactive({
  region: [],
  type: [{
    label: "开通",
    value: "开通"
  },
  {
    label: "报修",
    value: "报修"
  },
  ],
  store: [],
  orderStatus: [],
  workType: []
});

// 数据模型
const baseData = reactive({
  id: '',
  workSn: '',
  status: "",
  type: "报修",
  brand: '',
  brandId: '',
  storeName: '',
  storeId: null,
  contactName: '',
  contactPhone: '',
  address: '',
  region: '',
  regionIds: [],
  attachmentIds: [],
  rejectReason: '',
  desc: '',
  workContent: [],
  createTime: '',
})

const showModal = reactive({
  order: false,
  orderDetail: false,

})

const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryFields.page_num = current;
  queryFields.page_size = pageSize;
  queryPage(); //刷新数据
};

let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = selectedRows; //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = selectedRows;
    console.log(selected, selectedRows, changeRows);
  },
});




//title按钮事件 搜索、重置、开通工单
let queryFields = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})
const searchData = reactive({
  type: null,
  keyword: '',
  status: null,
})
const searchFields = [{
  title: '关键字',
  key: 'keyword'
}, {
  title: '类型',
  key: 'type'
},

{
  title: '状态',
  key: 'status'
}
]
const sift = () => {
  queryFields = {
    page_num: pagination.current,
    page_size: pagination.pageSize,
    type: searchData.type,
    keyword: searchData.keyword,
    status: searchData.status,
  }
  queryPage()
}
const resetSift = () => {
  for (let key in searchData) {
    searchData[key] = null
  }
  queryFields = {
    page_num: 1,
    page_size: 10,
  }
  queryPage()
}



//工作内容分类选择器
const workConentSelector = () => {
  serviceAxios({
    url: '/v1/work_order/work_order_type/selector',
    method: 'get',
  }).then(
    result => {
      let data = result.data.data
      options.workType = data.map(item => ({
        label: item.name,
        value: item.value
      }))
    }
  ).catch(
    error => {
      console.log(error)
    }
  )
}
workConentSelector()
//工单的增加、编辑、查看事件
let orderOperation = '' //判断是增加工单还是编辑工单
const orderFields = [
  {
    title: "工单类型",
    key: "type"
  },
  {
    title: "选择门店",
    key: "storeId",
  },
  {
    title: "门店名称",
    key: "storeName",
  },
  {
    title: "区域",
    key: "region",
  },
  {
    title: "门店地址",
    key: "address",

  },
  {
    title: "联系人",
    key: "contactName",
  },
  {
    title: "联系电话",
    key: "contactPhone",
  },
  {
    title: "描述",
    key: "desc",
  },
  {
    title: '附件',
    key: 'attachments'
  }
]

const resetOrder = () => {

  for (let key in baseData) {
    if (!['regionIds', 'type', 'brandId', 'storeId'].includes(key)) {
      baseData[key] = ''
    }

  }

  baseData.regionIds = []
  baseData.storeId = null
  baseData.type = '报修'
  fileList.value = []

  //刷新upload组件
  uploadFresh.value = !uploadFresh.value

}

const addOrder = () => {
  resetOrder()
  orderOperation = 'add'
  showModal.order = true
}

const editOrder = (record) => {
  uploadFresh.value = !uploadFresh.value
  orderOperation = 'edit'
  showModal.order = true
  for (let key in record) {
    baseData[key] = record[key]
  }
  if (record.workContent[0].data_attachments.length != 0) {
    // record.workContent[0].data_attachments.forEach(
    //   item => {
    //     fileList.value.push(item.id)
    //   }
    // )
    fileList.value = record.workContent[0].data_attachments.map(item => ({
      file_id: item.id,
      owner_class: item.owner_class,
      owner_id: item.owner_id
    }))
  }

}

const viewOrder = (record) => {

  fileList.value = []
  orderDetailFields.forEach(item => {
    baseData[item.key] = record[item.key]
  })
  baseData.rejectReason = record.rejectReason
  baseData.workContent = record.workContent
  if (record.workContent[0].data_attachments.length != 0) {
    record.workContent[0].data_attachments.forEach(
      item => {
        fileList.value.push(
          item.id
        )
      }
    )
  }

  if (baseData.workContent[0].type == '宽带接入') {
    workRef.value['宽带接入'].maintenancePhone = baseData.workContent[0].maintenance_phone
    workRef.value['宽带接入'].maintenanceName = baseData.workContent[0].maintenance_name
    workRef.value['宽带接入'].installerPhone = baseData.workContent[0].installer_name
    workRef.value['宽带接入'].installerName = baseData.workContent[0].installer_phone
  }
  // else if (baseData.workContent[0].type == '故障维修'){

  // }



  showModal.orderDetail = true
}

const submitOrder = () => {
  let url = ''
  let data = ''
  let flag = true
  let checkRepairFields = ['storeId', 'regionIds', 'desc']
  let checkNetworkFields = ['storeName', 'regionIds', 'address', 'contactName', 'contactPhone', 'desc']

  let attachment = fileList.value.map(item => item.file_id != undefined ? item.file_id : item)
  // fileList.value.forEach(item => {
  //   console.log("123", item.old)
  //   if (item.old != undefined && item.old == true) {
  //     attachment.push(item.uid)
  //   }
  //   else {
  //     if (item.response.message == 'success') {
  //       attachment.push(item.response.data[0].id)
  //     }
  //   }

  // })

  if (baseData.type == '报修') {
    for (let i = 0; i < checkRepairFields.length; i++) {
      if (baseData[checkRepairFields[i]] == '' || baseData[checkRepairFields[i]] == null || baseData[checkRepairFields[i]] == []) {
        flag = false
        break
      }
    }
  }
  else {
    for (let i = 0; i < checkNetworkFields.length; i++) {
      if (baseData[checkNetworkFields[i]] == '' || baseData[checkNetworkFields[i]] == null || baseData[checkNetworkFields[i]] == []) {
        flag = false
        break
      }
    }
  }
  if (flag == true) {
    if (orderOperation == 'add') {
      url = baseData.type == '报修' ? interfaces.costomerRepairAdd : interfaces.costomerOrderAdd
      data = {
        customer_id: baseData.brandId,
        customer_name: baseData.storeName,
        region_id: baseData.regionIds[baseData.regionIds.length - 1],
        contract_user_contact_name: baseData.contactName,
        contract_user_phone_number: baseData.contactPhone,
        data_attachment_ids: attachment,
        desc: baseData.desc == null ? '' : baseData.desc,
      }
      if (baseData.type == '报修') {
        data['contract_user_id'] = baseData.storeId

      } else {
        data['contract_user_name'] = baseData.storeName
        data['contract_user_address'] = baseData.address
      }

    }
    else if (orderOperation == 'edit') {
      url = baseData.type == '报修' ? interfaces.costomerRepairEdit : interfaces.costomerOrderEdit
      data = {
        id: baseData.id,
        desc: baseData.desc,
        contract_user_id: baseData.storeId,
        customer_id: baseData.brandId,
        contract_user_contact_name: baseData.contactName,
        contract_user_phone_number: baseData.contactPhone,
        customer_name: baseData.brand,
        region_id: baseData.regionIds[baseData.regionIds.length - 1],
        data_attachment_ids: attachment,
      }
      if (baseData.type != '报修') {
        data['contract_user_name'] = baseData.storeName
        data['contract_user_address'] = baseData.address
      }
    }



    serviceAxios({
      url: url,
      method: 'post',
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        showModal.order = false
        message.success('成功')
        queryPage()
      }
      else {
        message.error(result.data.message)
      }
    }).catch()

  }
  else {
    message.error('请检查数据')
  }
}
//获取门店信息 用于工单modal的选择门店的option属性
const contractPage = () => {
  serviceAxios({
    url: interfaces.contractPage,
    method: "post",
    data: {
      page_num: 1,
      page_size: 99999
    }
  }).then(result => {
    let data = []
    result.data.data.list.forEach(item => {
      data.push({
        label: item.contract_user.name,
        value: item.contract_user.id,
        brand: item.customer.name,
        brandId: item.customer.id,
        storeId: item.contract_user.id,
        storeName: item.contract_user.name,
        contactName: item.contract_user.contact_name,
        contactPhone: item.contract_user.phone_number,
        address: item.contract_user.address,
        regionIds: item.region.full_path_ids,
      })
      baseData.brandId = item.customer.id //品牌ID

    })


    options.store = data

  }).catch((err) => {
    console.log(err);
  })
}
contractPage()



//工单基础字段
const orderDetailFields = [
  {
    title: '工单类型',
    key: 'type'
  },
  {
    title: '工单状态',
    key: 'status'
  },

  {
    title: '门店名称',
    key: 'storeName'
  },
  {
    title: '地址',
    key: 'address'
  },
  {
    title: '联系人',
    key: 'contactName'
  },
  {
    title: '联系电话',
    key: 'contactPhone'
  },
  {
    title: '描述',
    key: 'desc'
  }
]
//工作内容字段 
const workType = {
  '故障维修': [{
    title: '附件',
    key: 'attachments'
  }],
  '宽带接入': [
    {
      title: '附件',
      key: 'attachments'
    },
    {
      title: '安装人',
      key: 'installerName'
    },
    {
      title: '安装人手机号',
      key: 'installerPhone'
    },
    {
      title: '维护人',
      key: 'maintenanceName'
    },
    {
      title: '维护人手机号',
      key: 'maintenancePhone'
    },
  ],
  '网关设备安装': [
    {
      title: '附件',
      key: 'attachments'
    }
  ],
  '测试报告': [
    {
      title: '附件',
      key: 'attachments'
    }
  ],
  '资源勘查': [
    {
      title: '附件',
      key: 'attachments'
    }
  ]
}
//工作内容Ref 
const workRef = ref({
  '故障维修': {
    attachments: []
  },
  '宽带接入': {
    attachments: [],
    installerName: "",
    installerPhone: "",
    maintenanceName: "",
    maintenancePhone: ""
  }
})







//请求
const queryPage = () => {
  serviceAxios({
    url: interfaces.constmerOrderPage,
    method: 'get',
    params: queryFields
  }).then(result => {
    if (result.data.message == 'success') {
      pagination.total = result.data.data.total;
      // 更新表格数据
      let data = []
      result.data.data.list.forEach(item => {
        data.push({
          id: item.id,
          key: item.id,
          workSn: item.work_sn,
          type: item.type,
          status: item.status,
          brand: item.customer_name,
          address: item.contract_user_address,
          brandId: item.customer_id,
          storeName: item.contract_user_name,
          storeId: item.contract_user_id,
          contactName: item.contract_user_contact_name,
          contactPhone: item.contract_user_phone_number,
          region: item.region,
          regionIds: item.region_path,
          rejectReason: item.reject_reason,
          desc: item.desc,
          workContent: item.work_contents,
          createTime: item.create_time,
        })
      });

      dataSource.data = data;
    }
    else {
      message.error(result.data.message)
    }

  }).catch(err => { console.log(err) })
}
queryPage()

const getTreeData = async () => {
  const treeData = await checkTree()
  options.region = treeData[0]["children"]

}
getTreeData()


//select 选择框change事件 
//门店名称change
const storeChange = (value, option) => {
  console.log(value, option)
  for (let key in option) {
    if (key != 'storeId') {
      baseData[key] = option[key]
    }
  }
  console.log(baseData)
}

//工单类型change
const typeChange = () => {
  for (let key in baseData) {
    if (!['regionIds', 'type', , 'brandId', , 'storeId'].includes(key)) {
      baseData[key] = ''
    }

  }
  baseData.storeId = null
  baseData.regionIds = []
}
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const filter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};
//文件处理
let fileList = ref([])




const fileChange = (events) => {
  fileList.value = []
  events.forEach(item => {
    fileList.value.push(item.isOld == true ? item.uid : item.response.data[0].id)
  })
}
//搜索字段选择器
//工单类型选择器
const orderTypeSelector = async (type) => {
  options.orderStatus = await selector.workOrderStatus(type)

}
const siftTypeChange = (value) => {
  orderTypeSelector(value)
}
</script>
<style lang="less" scoped>
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */

  .title-box-right {
    margin-left: auto;
  }

  .title-box-left {
    display: flex;
    // padding-top: 2px;
  }
}

.searchLabel {
  padding: 4px 10px;
  border: 1px solid #dddddd;
  background-color: #f5f4f4;
}

.orderTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.orderTable tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.orderTable tr td.column {
  background-color: #eff3f6;
  color: #393c3e;
  width: 100px;
}
</style>