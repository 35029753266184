<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class="header">
    <div class="back-container">
      <div class="back-text"
           @click='backHome'>首页</div>
      <div class="back-text"
           style='margin-left:10px'
           @click='toOrderList'>工单列表</div>
    </div>

    <!-- <div class='title'>工单</div> -->
  </div>

  <table class='orderTable'>
    <template v-for='item in orderFields'
              :key='item.key'>
      <tr v-if='item.title=="工单类型"'>
        <td class='column'>{{item.title}}</td>
        <td style="text-align: left;">
          <a-select :bordered='false'
                    :showArrow='false'
                    :placeholder="`请选择${item.title}`"
                    :options='options.type'
                    style='width:344px'
                    v-model:value="baseData.type"
                    @change='typeChange'></a-select>
        </td>
      </tr>
      <tr v-if='item.title=="选择门店"'>
        <td class='column'><span style="color: red;margin-left: 0px;"
                v-if='baseData.type=="报修"'>*</span>{{item.title}}</td>
        <td style="text-align: left;">
          <a-select :bordered='false'
                    :showArrow='false'
                    :placeholder="`请选择${item.title}`"
                    show-search
                    :filter-option="filterOption"
                    :options='options.store'
                    style='width:344px'
                    v-model:value="baseData.storeId"
                    @change='storeChange'></a-select>
          <!-- 
          <a-cascader v-model:value="baseData.storeId"
                      :showArrow='false'
                      :bordered='false'
                      :placeholder="`请选择${item.title}`"
                      :filter-option="filterOption"
                      :options='options.store'
                      style='width:344px'
                      @change='storeChange' /> -->
        </td>
      </tr>
      <tr v-else-if='item.title=="区域"'>
        <td class='column'><span v-if='baseData.type=="开通"'
                style="color: red;margin-left: 0px;">*</span>{{item.title}}
        </td>
        <td style="text-align: left;">
          <a-cascader :showArrow="false"
                      :bordered="false"
                      style='width:344px'
                      v-model:value="baseData.regionIds"
                      :show-search="{ filter }"
                      :options="options.region"
                      :placeholder="`请选择区域`" />
        </td>
      </tr>
      <tr v-else-if='["问题描述"].includes(item.title)'>
        <td class='column'><span style="color: red;margin-left: 0px;">*</span>{{item.title}}</td>
        <td>
          <a-input :bordered='false'
                   v-model:value="baseData[item.key]"
                   :placeholder="`请输入${item.title}`"></a-input>
        </td>

      </tr>
      <tr v-else-if='["附件"].includes(item.title)'>
        <td class='column'>{{item.title}}</td>
        <td style="text-align: left;">

          <uploadComponent :fileIds='fileList'
                           :freshFlag='uploadFresh'
                           @update:fileIds='(event)=>fileChange(event)'>
          </uploadComponent>
        </td>
      </tr>
      <tr v-else-if='!["区域","工单类型","选择门店","问题描述","附件"].includes(item.title)'>
        <td class='column'> <span v-if='baseData.type=="开通"'
                style="color: red;margin-left: 0px;">*</span>{{item.title}}
        </td>
        <td>
          <a-input :bordered='false'
                   v-model:value="baseData[item.key]"
                   :placeholder="`请输入${item.title}`"></a-input>

        </td>

      </tr>

    </template>
  </table>
  <a-button @click='submitOrder'
            type='primary'>提交</a-button>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import uploadComponent from '@/components/uploadComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import removeNullValues from "@/utils/removeNullValues";
import { message } from "ant-design-vue";
import { reactive, ref, onMounted, onUpdated, watch, computed } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router';
import { checkTree } from "@/utils/regionTree";
import { selector } from '@/utils/selector'
import dayjs from "dayjs";

import "dayjs/locale/zh-cn";


import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;
dayjs.locale("zh-cn");

let route = useRoute()
let router = useRouter()







// table列名数据
const columns = ref([

  {
    title: "工单号",
    dataIndex: "workSn",
    key: "workSn",
    width: '180px',
  },
  {
    title: "工单状态",
    width: '100px',
    dataIndex: "status",
    key: "status"
  },
  {
    title: "工单类型",
    width: '100px',
    dataIndex: "type",
    key: "type"
  },

  {
    title: "门店名称",
    dataIndex: "storeName",
    key: "storeName",
    width: '150px',
    ellipsis: true
  },

  {
    title: "门店地址",
    dataIndex: "address",
    key: "address",
    // width: '200px',
    ellipsis: true
  },
  {
    title: "联系人",
    dataIndex: "contactName",
    key: "contactName",
    width: '100px',
  },
  {
    title: "联系电话",
    dataIndex: "contactPhone",
    key: "contactPhone",
    width: '120px',
  },
  {
    title: "问题描述",
    dataIndex: "desc",
    key: "desc",
    width: 'true',
    ellipsis: true
  },
  {
    title: "拒绝原因",
    dataIndex: "rejectReason",
    key: "rejectReason",
    width: '100px',
    ellipsis: true
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
    width: '160px',
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    width: '150px',
  }
]);

//table数据源
const dataSource = reactive(
  {
    data: [],
  }
)

//选择框数据源
const options = reactive({
  region: [],
  type: [{
    label: "开通",
    value: "开通"
  },
  {
    label: "报修",
    value: "报修"
  },
  ],
  store: []
});

// 数据模型
const baseData = reactive({
  id: '',
  workSn: '',
  status: "",
  type: "报修",
  brand: '',
  brandId: '',
  storeName: '',
  storeId: null,
  contactName: '',
  contactPhone: '',
  address: '',
  region: '',
  regionIds: [],
  rejectReason: '',
  productInfo: "",
  desc: '',
  createTime: '',
})

const showModal = reactive({
  order: false,
})

const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryFields.page_num = current;
  queryFields.page_size = pageSize;
  queryPage(); //刷新数据
};

let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = selectedRows; //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = selectedRows;
    console.log(selected, selectedRows, changeRows);
  },
});




//title按钮事件 搜索、重置、开通工单
let queryFields = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})
const searchData = reactive({
  type: '',
  keyword: '',
  status: '',
})
const searchFields = [{
  title: '类型',
  key: 'type'
},
{
  title: '关键字',
  key: 'keyword'
},
{
  title: '状态',
  key: 'status'
}
]
const sift = () => {
  queryFields = {
    page_num: pagination.current,
    page_size: pagination.pageSize,
    type: searchData.type,
    keyword: searchData.keyword,
    status: searchData.status,
  }
  queryPage()
}
const resetSift = () => {
  queryFields = {
    page_num: 1,
    page_size: 10,
  }
  queryPage()
}


//工单的增加、编辑、查看事件
let orderOperation = '' //判断是增加工单还是编辑工单
const orderFields = [
  {
    title: "工单类型",
    key: "type"
  },
  {
    title: "选择门店",
    key: "storeId",
  },
  {
    title: "门店名称",
    key: "storeName",
  },
  {
    title: "产品信息",
    key: "productInfo",
  },
  {
    title: "区域",
    key: "region",
  },
  {
    title: "门店地址",
    key: "address",

  },
  {
    title: "联系人",
    key: "contactName",
  },
  {
    title: "联系电话",
    key: "contactPhone",
  },
  {
    title: "问题描述",
    key: "desc",
  },
  {
    title: "附件",
    key: "attachments",
  }
]
//定义上传组件刷新属性
const uploadFresh = ref(true)
const resetOrder = () => {
  for (let key in baseData) {
    if (!['regionIds', 'type', 'brandId', 'storeId'].includes(key)) {
      baseData[key] = ''
    }

  }

  baseData.regionIds = []
  baseData.storeId = null
  baseData.type = '报修'
  fileList.value = []
  uploadFresh.value = !uploadFresh.value

}

const addOrder = () => {
  resetOrder()
  orderOperation = 'add'
  showModal.order = true
}

const editOrder = (record) => {
  orderOperation = 'edit'
  showModal.order = true
  for (let key in record) {
    baseData[key] = record[key]
  }
}

const viewOrder = () => {

}

const submitOrder = () => {
  let url = ''
  let data = ''
  let flag = true
  let checkRepairFields = ['storeId', 'desc']
  let checkNetworkFields = ['storeName', 'regionIds', 'address', 'contactName', 'contactPhone', 'desc']
  let attachment = fileList.value


  if (baseData.type == '报修') {
    for (let i = 0; i < checkRepairFields.length; i++) {
      if (baseData[checkRepairFields[i]] == '' || baseData[checkRepairFields[i]] == null || baseData[checkRepairFields[i]] == []) {
        flag = false
        break
      }
    }
  }
  else {
    for (let i = 0; i < checkNetworkFields.length; i++) {
      if (baseData[checkNetworkFields[i]] == '' || baseData[checkNetworkFields[i]] == null || baseData[checkNetworkFields[i]] == []) {
        flag = false
        break
      }
    }
  }
  if (flag == true) {

    url = baseData.type == '报修' ? interfaces.costomerRepairAdd : interfaces.costomerOrderAdd
    data = {
      customer_id: baseData.brandId,
      customer_name: baseData.brand,

      region_id: baseData.regionIds[baseData.regionIds.length - 1],
      contract_user_contact_name: baseData.contactName,
      contract_user_phone_number: baseData.contactPhone,
      data_attachment_ids: attachment,
      desc: baseData.desc == null ? '' : baseData.desc,
    }
    if (baseData.type == '报修') {
      data['contract_user_id'] = baseData.storeId

    } else {
      data['contract_user_name'] = baseData.storeName
      data['contract_user_address'] = baseData.address
    }




    serviceAxios({
      url: url,
      method: 'post',
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        showModal.order = false
        message.success('成功')
        resetOrder()
      }
      else {
        message.error(result.data.message)
      }
    }).catch()

  }
  else {
    message.error('请检查数据')
  }
}
//获取门店信息 用于工单modal的选择门店的option属性
const contractPage = () => {
  serviceAxios({
    url: interfaces.contractPage,
    method: "post",
    data: {
      page_num: 1,
      page_size: 99999
    }
  }).then(result => {
    let data = []
    result.data.data.list.forEach(item => {
      data.push({
        label: item.contract_user.name,
        value: item.contract_user.id,
        brand: item.customer.name,
        brandId: item.customer.id,
        storeId: item.contract_user.id,
        storeName: item.contract_user.name,
        contactName: item.contract_user.contact_name,
        contactPhone: item.contract_user.phone_number,
        address: item.contract_user.address,
        regionIds: item.region.full_path_ids,
      })
      baseData.brandId = item.customer.id //品牌ID

    })


    options.store = data

  }).catch((err) => {
    console.log(err);
  })
}
contractPage()




const getTreeData = async () => {
  const treeData = await checkTree()
  options.region = treeData[0]["children"]
  console.log("treeData", treeData)
}
getTreeData()


//select 选择框change事件 
//门店名称change
const storeChange = (value, option) => {
  console.log(value, option)
  for (let key in option) {
    if (key != 'storeId') {
      baseData[key] = option[key]
    }
  }
  console.log(baseData)
}
//工单类型change
const typeChange = () => {
  for (let key in baseData) {
    if (!['regionIds', 'type', , 'brandId', , 'storeId'].includes(key)) {
      baseData[key] = ''
    }

  }
  baseData.storeId = null
  baseData.regionIds = []
}
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};


const filter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};
//文件处理
let fileList = ref([])
const fileChange = (events) => {
  fileList.value = []
  events.forEach(item => {
    fileList.value.push(item.isOld == true ? item.uid : item.response.data[0].id)
  })
}


//返回首页
const backHome = () => {
  router.push({ path: "/homePageData" })
}

//跳转添加工单
const toOrderList = () => {
  router.push({ path: "/customerWorkOrder" })
}
</script>
<style lang="less" scoped>
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */

  .title-box-right {
    margin-left: auto;
  }

  .title-box-left {
    display: flex;
    // padding-top: 2px;
    margin-left: -15px;
  }
}

.searchLabel {
  padding: 4px 10px;
  border: 1px solid #dddddd;
  background-color: #f5f4f4;
}

.orderTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.orderTable tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.orderTable tr td.column {
  background-color: #eff3f6;
  color: #393c3e;
  width: 20%;
  // text-align: left;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  padding: 10px 20px;
  /* margin-bottom: 20px; */
}

.back-container {
  display: flex;
  align-items: center;
}

.back-text {
  font-size: 16px;
  color: #333;
  cursor: pointer;
}
</style>