<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-model-argument -->


<template>
  <a-modal :maskClosable="false"
           width="600px"
           v-model:open="helperCenterRef"
           title="帮助中心">
    <a-table :columns="columns"
             :data-source="dataSource.data"
             :row-selection="rowSelection"
             :pagination="pagination"
             @change="handleTableChange"
             ref="tableRef"
             bordered>
      <template #bodyCell="{ column, record }">
        <template v-if="['name'].includes(column.dataIndex)">
          <div>
            {{ record.name }}
          </div>
        </template>

        <template v-if="['operations'].includes(column.dataIndex)">
          <div>
            <a class='editable-row-a'
               @click="viewHelper(record.attachments)">查看文档</a>

          </div>
        </template>

      </template>
      <template #title>
        <div class='searchDivContainer'>
          <div>
            <a-input addonBefore='关键字'
                     placeholder="请输入关键字"
                     v-model:value='siftRef.keyword'
                     style='width:180px'>
            </a-input>

            <a-button class="addDataButton"
                      type="primary"
                      @click="sift">搜索</a-button>

            <a-button class="addDataButton"
                      @click="resetSift">重置</a-button>
          </div>

        </div>
      </template>
    </a-table>
    <div v-if='viewHelper'>
      <div>
        <img src='@/static/home/标题.png'
             class='container-title-style'>
      </div>
    </div>
  </a-modal>

  <div class='container'
       ref='containerRef'>
    <a-layout>
      <a-layout-sider class="sider"
                      v-model:collapsed="state.collapsed"
                      :trigger="null"
                      collapsible>
        <img @click="toHome"
             v-if='!state.collapsed'
             style="width: 200px; height: 64px;cursor:pointer;"
             src="@/static/login/2018.png"
             alt="Logo" />

        <div v-if='state.collapsed'
             class='logo'>AIOps</div>
        <a-menu id="dddddd"
                v-model:openKeys="state.openKeys"
                v-model:selectedKeys="selectedKeysSide"
                mode="inline"
                theme="dark"
                :items="items.navigation_sides"
                @click="handleClickSide"
                @openChange="handleOpenChange"></a-menu>

      </a-layout-sider>
      <a-layout>

        <a-layout-header class="layout-header">
          <a-button type="link"
                    class='collapsed-button'
                    @click="toggleCollapsed">
            <MenuUnfoldOutlined v-if="state.collapsed" />
            <MenuFoldOutlined v-else />
          </a-button>
          <!-- <a-menu v-model:selectedKeys="selectedKeysHeader"
                  theme="dark"
                  mode="horizontal"
                  :style="{ lineHeight: '64px' }"
                  :items="items.navigation_headers"
                  @click="handleClickHeader">

          </a-menu> -->
          <div class="header-right">
            <span class="message-info"
                  @click='helperCenter'
                  style='font-size: 16px;color: rgb(134, 134, 136);margin-right:10px;    cursor: pointer;'>帮助中心</span>
            <a-dropdown>
              <div class='message-icon-box'
                   @click='toMessagePage'>
                <a-badge count="5">
                  <img class='message-image'
                       src="@/assets/消息提醒.png">
                </a-badge>
                <span class="message-info">消息提醒</span>
              </div>
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <a href="javascript:;"
                       @click="toMessagePage">消息1</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;"
                       @click="toMessagePage">消息1</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;"
                       @click="toMessagePage">消息1</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <a-dropdown>
              <div style="display:flex;cursor:pointer;align-items:center;">
                <img class='rounded-image'
                     src="https://images.gitee.com/uploads/images/2021/1101/141155_f3dfce1d_7382127.jpeg">
                <span class="user-info">{{ userName }}</span>
              </div>
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <a href="javascript:;"
                       @click="handleLogout">退出系统</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <!-- <span class="user-info"></span> -->
            <!-- <span class="user-info" style='cursor:pointer' @click="handleLogout">{{ "退出" }}</span> -->
          </div>
        </a-layout-header>
        <breadcrumb-component style="margin: 2px;margin-left:20px" />
        <a-layout-content style="margin: 0 0 0 16px;padding:0px !important">
          <!-- padding: '24px', -->
          <div :style="{ background: '#fff',height:(970)+'px',width:1690+'px' }">
            <router-view></router-view>
          </div>
        </a-layout-content>
        <!-- <a-layout-footer class="footer">
        <div class='footer-label'>AIOPS ©2024 Created by Yuhe Information Technology</div>
      </a-layout-footer> -->
      </a-layout>
    </a-layout>
  </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'

import BreadcrumbComponent from '@/components/BreadcrumbComponent.vue';
import { ref, watch, reactive, h, onMounted, onUnmounted } from "vue";
// import router from "@/router";
import serviceAxios from "../utils/serviceAxios";
import interfaces from "@/config";
import { message } from "ant-design-vue";
import { useRouter, useRoute } from 'vue-router';

import { useScreenSize } from '@/utils/useScreenSize';
import useItems from 'ant-design-vue/es/menu/src/hooks/useItems';
import {
  MailOutlined,
  CalendarOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
const { windowWidth, windowHeight } = useScreenSize();

let helperCenterRef = ref(false)
let containerRef = ref()
const state = reactive({
  rootSubmenuKeys: [],
  collapsed: false,
  selectedKeys: [],
  openKeys: [],
  preOpenKeys: [],
});

let router = useRouter()
const userName = ref("")
let breadcrumb_info = ref("");
const selectedKeysSide = ref();
const selectedKeysHeader = ref()
watch(selectedKeysSide, (selectedKeysSide) => {
  // console.log("selectedKeysSide", selectedKeysSide)
  localStorage.setItem("selectedKeysSide", selectedKeysSide)
})
// watch(selectedKeysHeader, (selectedKeysHeader) => {
//   // console.log("selectedKeysHeader", selectedKeysHeader)
//   localStorage.setItem("selectedKeysHeader", selectedKeysHeader)
//   // localStorage.removeItem("selectedKeysSide")
// })
const openKeys = ref([]);
const items = reactive({
  navigation_headers: [],
  navigation_sidesAll: {},
  navigation_sides: []

});

const handleClickSide = (menuInfo) => {
  if (menuInfo.key != undefined)
    console.log('click ', menuInfo);
  // breadcrumb_info.value = menuInfo.label;
  router.push(menuInfo.item.keyPath);
};

let selectSide = ''

//获取导航下的第一个可用路由
const getChildren = (sideNavigation) => {
  console.log("开始检测sideNavigation", sideNavigation)
  if (sideNavigation[0] != undefined) {
    if (sideNavigation[0].children != undefined) {
      openKeys.value = [sideNavigation[0].key]
      getChildren(sideNavigation[0].children)
    }
    else {
      console.log("keyPath", sideNavigation[0].keyPath)
      selectedKeysSide.value = [sideNavigation[0].key]
      selectSide = sideNavigation[0].keyPath
    }
  }
  else {
    selectSide = undefined
  }
}

//头部菜单点击事件
const handleClickHeader = (key) => {
  console.log("key", key)
  //key.item.originItemValue.title //头部菜单标题
  let menuKey = key.key
  if (menuKey == undefined) {
    menuKey = key
  }
  items.navigation_sides = items.navigation_sidesAll[menuKey]
  console.log('items.navigation_sides', items.navigation_sides)

  if (items.navigation_sides == undefined) {
    router.push('/login')
  }
  else {
    items.navigation_sides.forEach(
      item => {
        item['icon'] = h(AppstoreOutlined)
      }
    )
    getChildren(items.navigation_sides)
  }


  if (selectSide != '' && selectSide != undefined) {
    router.push(selectSide)
  } else {
    try {
      if (key.item.title == '首页') {
        router.push('/homeData')
      }
      else if (key.item.title == '信发系统') {
        pointShow()
      }
      else {
        router.push('/result')

      }
    }
    catch {

    }

  }
  console.log("selectSide", selectSide)

};
const handleLogout = () => {

  // localStorage.removeItem("token");
  // localStorage.removeItem("name");
  serviceAxios({
    url: interfaces.logout,
    method: 'get'
  }).then(
    result => {
      let role = localStorage.getItem("role")
      localStorage.clear()

      if (role != "外勤人员") {
        router.push("/login")
      }
      else {
        router.push("/loginTalent")
      }
    }
  ).catch()

};

let rules = {}
let menuid = []
const clearMenu = (data) => {
  let childrenData = []
  if (data != undefined) {

    data.forEach((item) => {
      if (menuid.includes(item.id)) {
        console.log("id重复", item)
      }
      else {
        menuid.push(item.id)
      }
      let scopData = {
        id: String(item.id),
        key: item.id,
        is_bottom: item.is_bottom,
        title: item.title,
        label: item.label,
        keyPath: item.key
      }
      if (item.children.length != 0) {
        scopData.children = clearMenu(item.children)
      }
      childrenData.push(scopData)


      if (item.is_bottom == 1 && item.rules != undefined) {
        rules[item.key] = []
        item.rules.forEach((rule) => {
          rules[item.key].push(rule.rule_path)
        })
      }
    }
    )
    return childrenData

  }
  else {
    return []
  }
}

//查询用户权限菜单
const queryUserMenu = () => {
  serviceAxios({
    url: interfaces.systemMe,
    method: "get",
    // data:''
  })
    .then((result) => {


      let sidesdate = clearMenu(result.data.rules);

      // let headersData = sidesdate.map(item => item.id)
      state.rootSubmenuKeys = sidesdate.map(item => item.key)
      // result.data.rules.forEach((item) => {
      //   // console.log(item)
      //   let scopData = {
      //     id: String(item.id),
      //     key: item.id,
      //     is_bottom: item.is_bottom,
      //     title: item.title,
      //     label: item.label,
      //     keyPath: item.key,
      //   }
      //   headersData.push(scopData)
      //   // sidesdate[String(item.id)] = clearMenu(item.children)
      //   sidesdate = clearMenu(item)
      //   console.log('侧边栏', sidesdate)

      // })
      localStorage.setItem("rootSubmenuKeys", JSON.stringify(state.rootSubmenuKeys))
      localStorage.setItem("rules", JSON.stringify(rules))
      localStorage.setItem("id", result.data.id)
      localStorage.setItem("role", result.data.role.name)
      // localStorage.setItem("headers", JSON.stringify(headersData))
      localStorage.setItem("sides", JSON.stringify(sidesdate))
      localStorage.setItem("userName", result.data.username)
      userName.value = result.data.username




      //初始化菜单
      // items.navigation_headers = headersData
      items.navigation_sidesAll = sidesdate
      items.navigation_sides = sidesdate
      // 默认选择的菜单
      // selectedKeysHeader.value = [headersData[0]["id"]]
      // handleClickHeader(items.navigation_headers[0])

      items.navigation_sides.forEach(
        item => {
          item['icon'] = h('AppstoreOutlined')
        }
      )

    })
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err)
    });
};


//检查权限
const checkPermissios = () => {
  if (localStorage.getItem("id") == null) {
    queryUserMenu()
  }
  else {
    // items.navigation_headers = JSON.parse(localStorage.getItem("headers"))


    items.navigation_sidesAll = JSON.parse(localStorage.getItem("sides"))

    state.rootSubmenuKeys = JSON.parse(localStorage.getItem("rootSubmenuKeys"))
    // selectedKeysHeader.value = [Number(localStorage.getItem("selectedKeysHeader"))] == null ? [] : [Number(localStorage.getItem("selectedKeysHeader"))] //缓存头部
    // let headerMeny = selectedKeysHeader.value == [] ? items.navigation_headers[0] : selectedKeysHeader.value[0]
    // console.log("headerMeny", headerMeny)
    // let headerkey = localStorage.getItem('selectedKeysHeader')
    items.navigation_sides = items.navigation_sidesAll
    try {
      items.navigation_sides.forEach(
        item => {
          item['icon'] = h(AppstoreOutlined)
        }
      )
    }
    catch {
      error => {
        console.log(error)
      }
    }

    // handleClickHeader(selectedKeysHeader.value == [] ? items.navigation_headers[0] : selectedKeysHeader.value[0])
    selectedKeysSide.value = [Number(localStorage.getItem("selectedKeysSide"))] == null ? [] : [Number(localStorage.getItem("selectedKeysSide"))] //缓存侧边栏
    // openKeys.value = localStorage.getItem("openKeys") == null ? [] : JSON.parse(localStorage.getItem("openKeys")) //缓存展开菜单
    userName.value = localStorage.getItem("userName")

  }
}
checkPermissios();

watch(openKeys, (val) => {
  localStorage.setItem("openKeys", JSON.stringify(val))
  console.log("openKeys", val);
});
const toHome = () => {
  router.push("/homeData");
}

const handleOpenChange = (keys) => {
  // console.log("openkeys", keys)
  // if (keys.length > 1) {
  //   openKeys.value = [keys[keys.length - 1]]; // 仅保留最后一个打开的key
  // } else {
  //   openKeys.value = keys; // 否则直接更新 openKeys
  // }


  // const latestOpenKey = keys.find(key => state.openKeys.indexOf(key) === -1);


  // if (state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
  //   state.openKeys = keys;
  // } else {
  //   state.openKeys = latestOpenKey ? [latestOpenKey] : [];
  // }

  const latestOpenKey = state.rootSubmenuKeys.filter(item => item == keys[keys.length - 1]);


  if (latestOpenKey.length != 0) {
    state.openKeys = [latestOpenKey[0]];
  }
  else {
    state.openKeys = keys;
  }

  console.log(keys, state.openKeys, state.rootSubmenuKeys, latestOpenKey)
}


const toggleCollapsed = () => {
  state.collapsed = !state.collapsed;
  // state.openKeys = state.collapsed ? [] : state.preOpenKeys;
};


const pointShow = () => {
  serviceAxios({
    url: '/v1/info_dis/point_show/get_token',
    method: 'post'
  }).then(result => {
    if (result.data.message = 'success') {
      let pointShowUrl = result.data.data
      window.open(pointShowUrl)

    }
    else {
      message.error(result.data.message)
    }
  }).catch(error => {
    console.log(error)
  })
}

const toMessagePage = () => {
  router.push("/test");
}




const originalWidth = 1920; // 原始宽度
const originalHeight = 1080; // 原始高度

function init (width, height) {
  const element = containerRef.value;

  if (!element) {
    console.error('Container element not found.');
    return;
  }

  // 计算缩放比例
  // const hScale = visibleHeight / height;
  // const wScale = visibleWidth / width;
  console.log('window.inner宽高', window.innerWidth, window.innerHeight)
  const hScale = window.innerHeight / height;
  const wScale = window.innerWidth / width;
  console.log('高宽比', hScale - 0.5, wScale)

  element.style.transform = `scale(${wScale}, ${hScale})`;
  element.style.transformOrigin = "left top";
  element.style.overflow = 'hidden';


}




onMounted(() => {
  init(1920, 1080);
  window.addEventListener('resize', handleResize);
});
const lazyFun = ref(null);

const handleResize = () => {
  clearTimeout(lazyFun);
  lazyFun.value = setTimeout(() => {
    init(1920, 1080);
  }, 600);
};


onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});


//帮助中心
const helperCenter = () => {
  queryHelp()
  helperCenterRef.value = true
}
//定义筛选字段
const siftRef = ref({
  keyword: ''
})
//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 16, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});
//querypage查询字段
let queryFields = {
  page_num: pagination.current,
  page_size: pagination.pageSize,
}

// 处理表格分页变化
const handleTableChange = async (newPagination) => {
  const { current, pageSize } = newPagination;
  console.log('pagination change', current)
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryFields.page_num = current;
  queryFields.page_size = pageSize;
  queryHelp();
};
const sift = () => {
  queryFields['keyword'] = siftRef.value.keyword
  queryFields['page_num'] = 1
  queryFields['page_size'] = 10
  queryHelp()
}
const resetSift = () => {
  siftRef.value.keyword = ''
  queryFields = {
    page_num: 1,
    page_size: 10,
  }
  queryHelp()
}
let dataSource = reactive({
  data: "",
});
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "标题",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "页面路径",
    dataIndex: "pagePath",
    key: "pagePath",
  },
  {
    title: "标签",
    dataIndex: "tagsName",
    key: "tagsName",
  },

  {
    title: "操作",
    dataIndex: "operations",
    key: "operations",
  }


];

const queryHelp = () => {
  serviceAxios({
    url: interfaces.docPage,
    method: "get",
    params: queryFields,
  })
    .then((result) => {
      if (result.status == 200) {
        //修改分页模型
        pagination.total = result.data.data.total;

        dataSource.data = result.data.data.list.map(item => ({
          id: item.id,
          key: item.id,
          title: item.title,
          pagePath: item.page_path,
          tags: item.tags,
          tagsName: item.tags.map(tags => tags.name).join(" "),
          relatedDocs: item.related_docs,
          attachments: item.attachments,
          content: item.content,
          createTime: item.create_time
        }));

        selectedRowKey.value = [];
        console.log("dataSource.data", dataSource.data)

      }
    })
    .catch((err) => {
      console.log(err);
    });
}
</script>
<style lang="less" scope>
.container {
  font-family: "Microsoft YaHei";
  width: 1920px;
  height: 1080px;
  transform-origin: 0 0;
  // transform: scale(0.975, 0.233333);
  display: flex;
  flex-direction: column;
}

.layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #10092c !important;
}
.sider {
  background-color: #10092c !important;
}

.menu-item-right-align {
}
.footer {
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: center;
  height: 20px;
  .footer-label {
    margin-top: -9px;
  }
}

.header-right {
  display: flex;
  align-items: center;
  margin-left: auto; /* 使得用户名和退出按钮位于最右边 */
}

.user-info {
  margin: 5px; /* 根据需要调整用户名和退出按钮之间的间距 */
  font-size: 17px;
  color: rgb(134, 134, 136);
}
.ant-menu,
.ant-menu-sub,
.ant-menu-inline {
  color: white;
  // background-color: #10092c !important;
  color: white;
  background-color: transparent !important; /* 子菜单背景也透明 */
}

.ant-menu-inline {
  text-align: left;
  padding-left: 0px; /* 根据需要调整 */
}
.ant-menu-inline > .ant-menu-item {
  text-align: left;
  padding-left: 0px; /* 根据需要调整 */
}

.rounded-image {
  width: 30px; /* 您指定的宽度 */
  height: 30px; /* 您指定的高度 */
  border-radius: 50%; /* 将边角弧度设为50%以形成圆形 */
  object-fit: cover; /* 保证图片填充圆形同时不失比例 */
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.07); /* 您指定的阴影效果 */
}

.message-icon-box {
  display: flex;
  align-items: center;
  margin-right: 10px;
  .message-image {
    width: 30px;
    height: 30px;
  }
  .message-info {
    margin: 5px;
    font-size: 16px;
    color: rgb(134, 134, 136);
  }
}

.collapsed-button {
  // background-color: #10092c;
  margin: 0px 10px 0px -50px;
  height: 64px;
  color: white;
}
.collapsed-button:hover {
  color: white;
}
.logo {
  width: 80px;
  color: white;
  height: 64px;
  cursor: pointer;
  font-size: 23px;
  text-align: center;
  padding-top: 15px;

  font-family: "Microsoft YaHei";
  background: -webkit-linear-gradient(left, #0f0, #00f) 0 0 no-repeat; /*设置线性渐变*/
  -webkit-background-size: 80px; /*设置背景大小*/
  -webkit-background-clip: text; /*背景被裁剪到文字*/
  -webkit-text-fill-color: rgba(255, 255, 255, 0.3); /*设置文字的填充颜色*/
  -webkit-animation: shine 0.5s infinite; /*设置动画*/
}
@-webkit-keyframes shine {
  /*创建动画*/
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

// //调整head行属性
// .ant-table-thead > tr > th {
//   padding: 5px !important;
// }
// .ant-table-tbody > tr > td {
//   padding: 5px !important;
// }

//table头部内边距
.ant-table-title {
  padding: 2px !important;
}
// .ant-form-item {
//   margin-bottom: 0px;
// }
</style>