//租户界面特定的路由
export const specialRules = [
  // {
  //   "id": 90,
  //   "key": "/homePageData",
  //   "label": "首页",
  //   "order": 0,
  //   "title": "首页",
  //   "is_bottom": 1,
  //   "parent_id": 25,

  // },
  {
    "id": 91,
    "key": "/门店列表",
    "label": "门店信息",
    "order": 1,
    "title": "门店信息",
    "is_bottom": 0,
    "parent_id": 25,
    "children": [
      {
        "id": 92,
        "key": "/customerView",
        "label": "门店列表",
        "order": 0,
        "title": "门店列表",
        "is_bottom": 1,
        "parent_id": 91,

      },
      {
        "id": 93,
        "key": "/storeMessage",
        "label": "运行状态",
        "order": 1,
        "title": "运行状态",
        "is_bottom": 1,
        "parent_id": 91,

      },
      {
        "id": 94,
        "key": "/glopt",
        "label": "拓扑数据",
        "order": 2,
        "title": "拓扑数据",
        "is_bottom": 1,
        "parent_id": 91,

      }
    ]
  },
  {
    "id": 95,
    "key": "/gongdandemo",
    "label": "工单管理",
    "order": 2,
    "title": "工单管理",
    "is_bottom": 0,
    "parent_id": 25,
    "children": [
      {
        "id": 96,
        "key": "/ticketView",
        "label": "工单列表",
        "order": 2,
        "title": "工单列表",
        "is_bottom": 1,
        "parent_id": 95,

      },
      {
        "id": 98,
        "key": "/addTicket",
        "label": "添加工单",
        "order": 3,
        "title": "添加工单",
        "is_bottom": 1,
        "parent_id": 95,
      }
    ]
  }

]