<!-- eslint-disable vue/no-v-model-argument -->
<template>

  <div class='loginView-container'>
    <div class='loginView-header'>
      <!-- <div class="header-yuhelogo">
      </div> -->
      <p class="headerTitle">域禾集控AIOps</p>
      <div class="headerNav">
        <span class="headerNavDl">

          <a @click="toUser"
             class="right-title">AIOps系统</a>
          <a @click="toTicket"
             class="right-title">工单系统</a>
        </span>
      </div>
    </div>
    <div class='loginView-content'>
      <div style='width:100%;display:flex; align-items: center;justify-content: center;'>
        <div class="content-yuhelogo">
        </div>
        <div class="loginView-box">
          <h2 class='loginView-title'>账号登录</h2>

          <div class='loginView-input-box'>
            <a-input class='loginView-input'
                     type="text"
                     placeholder="请输入用户名"
                     v-model:value="formState.username">
            </a-input>
            <a-input class='loginView-input'
                     type="password"
                     placeholder="请输入密码"
                     v-model:value="formState.password">
            </a-input>
          </div>

          <div class='checkbox-forget'>
            <a-checkbox style='margin-left:30px;font-size:15px;color:#727C88;'
                        v-model:checked="checked">30天内免登录
            </a-checkbox>
            <a style='margin-right:30px;cursor:pointer;color:#727C88;'
               @click="1">忘记密码</a>
          </div>
          <div class="loginView-input-box">
            <a-button type="primary"
                      class="loginView-button"
                      :loading="isLoading"
                      @click="onFinish">登录</a-button><br>
          </div>
          <div class="agreement">
            <a style='font-size:14px;cursor:pointer;color:#727C88;'>阅读并接受《服务条款》和《隐私政策》</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { useRouter } from 'vue-router'
import serviceAxios from "../utils/serviceAxios";

import { message } from "ant-design-vue";

import { toRaw } from "@vue/reactivity";
import { reactive, ref, onBeforeUnmount, onMounted } from "vue";
import qs from 'qs';
import encrypt from "../utils/encrypt"
import interfaces from '@/config';


let access_token = ''  //token

const router = useRouter()

const formState = reactive({
  username: "",
  password: "",

});

let isLoading = ref(false)
// 处理回车键登录
const handleEnterLogin = (event) => {
  if (event.key === 'Enter') {
    onFinish();
  }
};
// 注册全局的 keyup 事件监听器
onMounted(() => {
  document.addEventListener('keyup', handleEnterLogin);
});

// 移除全局的 keyup 事件监听器
onBeforeUnmount(() => {
  document.removeEventListener('keyup', handleEnterLogin);
});

//登录
const onFinish = () => {
  isLoading.value = true
  let data = {
    username: formState.username,
    password: encrypt.loginUserCrypto(formState.password)
  }
  data = toRaw(data)
  serviceAxios({
    url: "/v1/secure/token",
    method: "post",
    data: qs.stringify(data),
  })
    .then((result) => {
      console.log(result);

      access_token = result.data.access_token
      localStorage.setItem('token', access_token)
      isLoading.value = false
      // store.state.text = true;
      // console.log(store.state.text)
      message.success('登录成功');

      regionTree()
    })
    .catch((err) => {

      console.log(err);
      console.log("错误信息", err.response.data.detail)
      message.error(err.response.data.detail);
    });


};

let regionData = []
//区域信息
const clearRegionalTree = (data) => {
  let response = [];
  data.forEach((item) => {
    if (item.children != undefined) {
      response.push({
        label: item.name,
        value: item.id,
        children: clearRegionalTree(item.children),
      });
    } else {
      response.push({
        label: item.name,
        value: item.id,
      });
    }
  });
  return response;
};
const regionTree = () => {
  serviceAxios({
    url: interfaces.reginalTree,
    method: "get",
  })
    .then((result) => {
      // regionData = clearRegionalTree(result.data.data[0].children);
      regionData = clearRegionalTree(result.data.data);
      localStorage.setItem("region", JSON.stringify(regionData))
      router.push(
        {
          path: '/home'
        }
      )
    })
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
};

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

// const checkPermissios = () => {
//   if (localStorage.getItem("id") == null) {
//     regionTree();
//   }
//   else {
//     router.push({ path: '/home' })
//   }
// }
// checkPermissios()


const toUser = () => {
  router.push("/loginView")
}

const toTicket = () => {
  router.push("/loginTalent")
}
</script>



<style lang='less' >
.loginView-container {
  height: 100%;
  background: url("~@/static/login/202406131349.jpeg") no-repeat;
  background-size: cover;
}
.loginView-header {
  height: 10%;
  .header-yuhelogo {
    color: #000;
    font-size: 12px;
    line-height: 166.6%;
    text-align: center;
    font-family: PingFangSC-Regular, Microsoft YaHei, "\5FAE\8F6F\96C5\9ED1",
      verdana, sans-serif, Simsun, STXihei;

    // background: url("~@/assets/headerLogo.png") 50% no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    // width: 200px;
    // height: 130px;
    width: 45px;
    height: 45px;
  }

  .headerTitle {
    font-family: PingFangSC-Regular, Microsoft YaHei, "\5FAE\8F6F\96C5\9ED1",
      verdana, sans-serif, Simsun, STXihei;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 16px;
    height: 24px;
    line-height: 24px;
    padding-left: 16px;
    width: 156px;
    display: block;
    position: absolute;
    top: 23px;
    left: 204px;
    border-left: 1px solid #b8bdcf;
    color: #888;
    text-align: left;
  }
}
.loginView-content {
  height: 80%;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  .content-yuhelogo {
    color: #000;
    font-size: 12px;
    line-height: 166.6%;
    text-align: center;
    font-family: PingFangSC-Regular, Microsoft YaHei, "\5FAE\8F6F\96C5\9ED1",
      verdana, sans-serif, Simsun, STXihei;

    background: url("~@/static/login/yuhe20240524.png") 50% no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    // width: 200px;
    // height: 130px;
    width: 600px;
    height: 600px;
    // margin-left: 22%;
  }
  .loginView-box {
    width: 400px;
    height: 488px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-left: 4.5%;

    .loginView-title {
      color: black;
      padding-top: 35px;
    }

    .loginView-input-box {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      margin-top: 35px;
    }

    .loginView-input {
      // border: 0;
      font-size: 13px;
      width: 340px;
      height: 50px;
      font-size: 15px;
      color: rgb(4, 4, 4);
      padding: 5px 10px;
      outline: none;
      margin-bottom: 15px;

      &::placeholder {
        color: #e2d7d3;
        font-size: 13px;
      }
      padding-left: 17px;
    }
    .checkbox-forget {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 15px;
      font-size: 14px;
    }

    .loginView-button {
      margin-top: 16px;
      width: 340px;
      height: 49px;
      border-radius: 5px;
      border: 0;
      color: #fff;
      text-align: center;
      line-height: 20px;
      font-size: 21px;

      // background-image: linear-gradient(to right, #30cfd0, #330867);
    }
    .agreement {
      text-align: center; /* 文本居中 */
      margin-top: 42px;
    }
  }
}
.headerNav {
  font-family: PingFangSC-Regular, Microsoft YaHei, "\5FAE\8F6F\96C5\9ED1",
    verdana, sans-serif, Simsun, STXihei;
  position: absolute;
  top: 20px;
  right: 40px;
  text-align: right;
  color: #555;
  font-size: 12px;
  line-height: 17px;
}
.headerNavDl {
  font-family: PingFangSC-Regular, Microsoft YaHei, "\5FAE\8F6F\96C5\9ED1",
    verdana, sans-serif, Simsun, STXihei;
  text-align: right;
  color: #555;
  font-size: 16px;
  line-height: 17px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 3px 0;
}
.right-title {
  font-family: PingFangSC-Regular, Microsoft YaHei, "\5FAE\8F6F\96C5\9ED1",
    verdana, sans-serif, Simsun, STXihei;
  text-align: right;
  line-height: 17px;
  text-decoration: none;
  padding-left: 12px;
  display: inline-block;
  vertical-align: middle;
  color: #585e6d;
  font-size: 15px;
  cursor: pointer;
}
// span {
//   color: #fff;
// }
</style>